// import dependencies
import React from "react";
import { navigate } from 'gatsby';

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import * as styles from "./styles/posts-nav-buttons.module.scss";

export default function PostNavButtons() {
    return (
        <div className={styles.postTopBar}>
            <button aria-label="Go back"  onClick={() => { navigate(-1) }}>
                <p><FontAwesomeIcon icon={["fas", "arrow-left"]} /></p>
            </button>
            <div className={styles.separator}></div>
        </div>
    );
}
// import dependencies
import React from 'react';
import { graphql } from 'gatsby';

// import components
import Layout from '../components/layout';
import Post from "../components/post";
import PostNavButtons from '../components/posts-nav-buttons';
import Section from '../components/section';

// import styles and assets
import * as styles from './styles/blog-page.module.scss';

export default function ArchivePost({ data }) {
	const { edges } = data.allMarkdownRemark;

	return (
		<Layout>
			<Section>
				<PostNavButtons />
				<div className={styles.blogContainer}>
					<div className={styles.blogPageHeader}>
						<h1>Entradas</h1>
						<h4>{edges[0].node.frontmatter.date.slice(0, 10)}</h4>
					</div>
					{edges.map(({ node }) => {
						return (
							<Post index={node.id} node={node} />
						);
					})}
				</div>
			</Section>
		</Layout>
	);
}

export const pageQuery = graphql`
  	query($year: Int, $month: Int) {
	  allMarkdownRemark(
		sort: {fields: frontmatter___date, order: DESC}
		filter: { frontmatter: {year: {eq: $year}, month: {eq: $month}} }
	  ) {
		totalCount
		edges {
		  node {
			id
			excerpt(pruneLength: 200, truncate: true, format: HTML)
			frontmatter {
				title
				date
				path
				tags
				featuredImage {
					childImageSharp {
						gatsbyImageData(width: 600, height: 380, formats: [WEBP])
					}
				}
			}
		  }
		}
	  }
	}
`;